import { create } from "zustand";

const defaultProps = {
    calendarOpen: false,
    moduleOpen: false,
    roomsOpen: false,
}

export const useAppStore = create((set, get) => ({
    ...defaultProps,
    setCalendarOpen: bool => set(() => ({ calendarOpen: bool })),
    setModuleOpen: bool => set(() => ({ moduleOpen: bool })),
    setRoomsOpen: bool => set(() => ({ roomsOpen: bool })),
}))