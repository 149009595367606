export function reviveObject(object) {
    for (let [key, value] of Object.entries(object)) {
        if (typeof value === 'string' && /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/.test(value)) {
            object[key] = new Date(value);
        }
        if (typeof value === 'object' && value !== null) {
            object[key] = reviveObject(value)
        }
    }
    return object;
}